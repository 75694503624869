import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles({
	//presentacion
	presentacion: {
		position: 'relative',
		width: '100%',
		alignSelf: 'flex-start',
		justifyContent: 'space-between',
		display: 'flex',
		flexDirection: 'column',
		marginBottom: 80,
		alignItems: 'center',
		height: 80,
		['@media (min-width: 768px)']: {
			flexDirection: 'row',
		},
		['&::before']: {
			position: 'absolute',
			top: '50%',
			left: '50%',
			content: "''",
			width: '100vw',
			height: '100%',
			transform: 'translate(-50%,-50%)',
			backgroundColor: '#222222',
		},
	},
	imagenLogoFidelNps: {
		transform: 'translateX(-10%)',
		width: 'max-content',
		height: 40,
		padding: 10,
		['@media (min-width: 1280px)']: {
			height: 50,
		},
	},
	tituloTextoNps: {
		position: 'relative',
		fontFamily: "'Roboto', 'Helvetica', 'Arial', 'sans-serif'",
		fontWeight: 700,
		fontSize: '.9em',
		color: '#ffff',
		textAlign: 'center',
		display: 'block',
	},
	contenedor: {
		display: 'flex',
		flexDirection: 'column',
		margin: '0 auto',
		padding: '0 8px',
		['@media (min-width: 768px)']: {
			maxWidth: '65%',
		},
		['@media (min-width: 1024px)']: {
			maxWidth: '55%',
		},
	},
	contListaBtn: {
		margin: '10px auto',
		['@media (min-width: 1024px)']: {
			width: 'max-content',
		},
	},
	seccionesScore: {
		marginBottom: 30,
		display: 'flex',
		flexDirection: 'column',
		position: 'relative',
	},
	seccionesScoreImagen: {
		position: 'absolute',
		top: '-5%',
		right: '-2%',
		width: 80,
		height: 80,
		objectFit: 'cover',
	},
	estilosModal: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 400,
		backgroundColor: '#ffff',
		padding: 20,
		borderRadius: 4,
	},
	contScore: {
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		marginTop: 5,
		padding: 8,
		borderRadius: 4,
		backgroundColor: '#b9dbbae3',
		border: '1px solid #ffff',
	},
	tituloScore: {
		fontFamily: "'Roboto', 'Helvetica', 'Arial', 'sans-serif'",
		fontWeight: 400,
		color: '#191919',
		fontSize: '1.2em',
		letterSpacing: '0.00938em',
		textAlign: 'center',
		['@media (min-width: 1280px)']: {
			fontSize: '1.3em',
		},
	},
	tituloSeccion: {
		fontFamily: "'Roboto', 'Helvetica', 'Arial', 'sans-serif'",
		fontWeight: 400,
		color: '#ffff',
		letterSpacing: '0.00938em',
		fontSize: '1.8em',
		textAlign: 'center',
	},
	leyendaScore: {
		fontFamily: "'Roboto', 'Helvetica', 'Arial', 'sans-serif'",
		fontWeight: 400,
		letterSpacing: '0.00938em',
		display: 'inline-block',
		width: '50%',
		fontSize: '.7em',
		marginTop: '4px',
		color: '#191919',
	},
	subtituloComentario: {
		fontSize: '.8em',
		letterSpacing: '0.00938em',
		fontFamily: "'Roboto', 'Helvetica', 'Arial', 'sans-serif'",
		fontWeight: 400,
	},
	contBtnScore: {
		display: 'flex',
		width: '100%',
		justifyContent: 'center',
		alignItems: 'center',
	},
	btnScore: {
		border: '1.6px solid #d0d0d0',
		padding: 8,
		margin: '0px 2px',
		borderRadius: 999,
		textAlign: 'center',
		outline: 'none',
		cursor: 'pointer',
		transition: 'all .6s ease',
		width: 30,
		['@media (min-width: 768px)']: {
			width: 45,
			height: 50,
			padding: 10,
		},
		['@media (min-width: 1280px)']: {
			width: 52,
		},
	},
	btnScoreMalo: {
		borderBottom: '1.6px solid #FF0101',
		'&:hover, &:focus': {
			border: '1.8px solid #FF0101',
			transition: 'all .8s ease',
			boxShadow: 'inset 0 -3.25em 0 0 #ff2c2ce0',
			transform: 'scale(1.05)',
		},
	},
	btnScoreBueno: {
		borderBottom: '1.6px solid #F5E719',
		'&:hover, &:focus': {
			border: '1.8px solid #F5E719',
			transition: 'all .8s ease',
			boxShadow: 'inset 0 -3.25em 0 0 yellow',
			transform: 'scale(1.05)',
		},
	},
	btnScoreMBueno: {
		borderBottom: '1.6px solid #23F113',
		'&:hover, &:focus': {
			border: '1.8px solid #23F113',
			transition: 'all .8s ease',
			boxShadow: 'inset 0 -3.25em 0 0 #5bf05be6',
			transform: 'scale(1.05)',
		},
	},
	btnScoreExcelente: {
		borderBottom: '1.6px solid #23F113',
		'&:hover, &:focus': {
			border: '1.8px solid #23F113',
			transition: 'all .8s ease',
			boxShadow: 'inset 0 -3.25em 0 0 #5bf05be6',
			transform: 'scale(1.05)',
		},
	},
	textAreaScore: {
		fontFamily: "'Roboto', 'Helvetica', 'Arial', 'sans-serif'",
		fontWeight: 400,
		border: 'none',
		height: 80,
		marginTop: 10,
		borderRadius: 4,
		fontSize: '.9em',
		textDecoration: 'none',
		outline: 'none',
		padding: 8,
	},
	btnScoreEnviar: {
		fontFamily: "'Roboto', 'Helvetica', 'Arial', 'sans-serif'",
		fontWeight: 400,
		border: 'none',
		padding: 10,
		width: 200,
		fontSize: 16,
		letterSpacing: '0.00938em',
		borderRadius: 4,
		margin: '30px 0 60px 0',
		backgroundColor: '#d17a29',
		color: 'white',
		cursor: 'pointer',
		transition: 'all .5s ease',
		['@media (min-width: 1024px)']: {
			width: 350,
			padding: 15,
		},
		'&:hover': {
			transform: 'translateY(-.45em)',
			boxShadow: '0 .7em .7em -.6em #454545',
			transition: 'all .5s ease',
		},
	},
	comentarioSeccion: {
		marginTop: 20,
		color: '#191919',
		marginBottom: 10,
		width: '95%',
		display: 'flex',
		flexDirection: 'column',
	},
	loaderNpsFondo: {
		position: 'fixed',
		zIndex: 1,
		top: 0,
		left: 0,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		width: '100%',
		height: '100%',
	},
	loaderNpsFondoImg: {
		width: 240,
		objectFit: 'contain',
	},
	modalError: {
		height: 'max-content',
		padding: 20,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column',
		['@media (min-width: 1024px)']: {
			height: '65vh',
		},
	},
	modalErrorImg: {
		width: '100%',
		height: '100%',
		objectFit: 'contain',
	},
	modalErrorInfo: {
		margin: '10px auto',
		height: '50%',
	},
	selectedStyles: {
		transition: 'transform .8s ease',
		transform: 'scale(1.05) translateY(-.55em)',
		boxShadow: '0px 7px 5px 0px rgba(27, 27, 27, 0.30)',
	},
	modalErrorTexto: {
		fontFamily: "'Roboto', 'Helvetica', 'Arial', 'sans-serif'",
		fontWeight: 400,
		color: '#ffff',
		'&:nth-child(1)': {
			fontSize: '2em',
		},
		'&:nth-child(2)': {
			fontSize: '1.5em',
		},
	},
});