import { errorHandling, ventasApi } from "../../api";
import { PROVINCES } from "../../constants";
import { ApiResponse } from "../types";
import { Locality, State } from "./locations.types";

export const getStates = async (): Promise<ApiResponse<State[]>> => {
  return await ventasApi
    .get<State[]>(PROVINCES)
    .then(({ data }) => {
      return { result: true, data };
    })
    .catch((err:any) => err);
};

export const getLocalities = async (idProvince:number): Promise<ApiResponse<Locality[]>> => {
  return await ventasApi
    .get<Locality[]>(`api/locations/states/${idProvince}/cities`)
    .then(({ data }) => {
      return { result: true, data };
    })
    .catch(errorHandling);
};