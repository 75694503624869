import { ApiMarketing } from "../../api.Marketing";
import { ApiResponse } from "../types";
import { CUSTOMER_NPS, REPLY_NPS } from "./constants";
import { customerInfo, replyNps, replyResponse } from "./replyNps.types";

//info del usuario y el NPS asociado
export const getCustomerInfo = async (hash: string): Promise<customerInfo> => {
  return await ApiMarketing.get<customerInfo[]>(`${CUSTOMER_NPS}`, {
    params: { hash },
    withCredentials: false,
  })
    .then(({ data }) => data[0])
    .catch((err) => err);
};

//enviar respuesta
export const sendReply = async (
  netPromoterScoreId: number,
  customerId: number,
  netPromoterScoreCustomerReplyRequest: replyNps
): Promise<ApiResponse<any>> => {
  return await ApiMarketing.post(
    `${REPLY_NPS}/${netPromoterScoreId}/customers/${customerId}/reply`,
    netPromoterScoreCustomerReplyRequest
  );
};
