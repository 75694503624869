import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import { SectionScore } from "../../../components/SectionScore/SectionScore";
import { useStyles } from "./NpsScore.styles";
import { useEffect, useState } from "react";
import {
  AreasProps,
  InfoModal,
  OpinionProps,
  defaultValues,
  InfoCustomer,
} from "../NpsInterfaces/NpsInterfaces.types";
import {
  getCustomerInfo,
  sendReply,
} from "../../../apiMarketing/services/replyNps/replyNps.services";
import { useParams } from "react-router-dom";
import fidelBlanco from "./../../../img/fidelBlanco.png";
import ErrorNps from "../../../img/ErrorNps.svg";
import "./../../../components/Background/Background.css";
import "./../../../components/SectionScore/LoaderNps.css";
import {
  customerInfo,
  replyNps,
} from "../../../apiMarketing/services/replyNps/replyNps.types";

export default function NpsScore() {
  const classes = useStyles();
  document.title = "N.P.S.";
  //#region VARIABLES DE ESTADO
  const [areas, setAreas] = useState<AreasProps>({});
  const [opinion, setOpinion] = useState<OpinionProps>(defaultValues.opinion);
  const [abrirModal, setAbrirModal] = useState<InfoModal>(
    defaultValues.infoModal
  );
  const [infoCustomer, setInfoCustomer] = useState<InfoCustomer>(
    {} as InfoCustomer
  );
  const [loader, setLoader] = useState<boolean>(false);
  let { hash }: any = useParams();
  //#endregion

  useEffect(() => {
    const decodeHash = decodeURIComponent(hash);
    consultarInfo(decodeHash);
  }, []);

  const consultarInfo = (res: string) => {
    setLoader(true);
    setAreas({});
    getCustomerInfo(res)
      .then((res) => {
        let validacion = validarEncuesta(res);
        if (validacion) {
          let {
            NetPromoterScore,
            accountId,
            id,
            externalId,
            showSupport,
            showApplicationMobile,
            showApplicationWeb,
          } = res;

          setAreas({
            sistema: showApplicationWeb,
            appMovil: showApplicationMobile,
            soporte: showSupport,
          });
          setInfoCustomer({
            id,
            externalId,
            accountId,
            NetPromoterScore,
          });
        }
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        mensajeModal(
          true,
          false,
          "¡Oops! Ocurrió un problema.",
          "La encuesta no existe para este cliente."
        );
      });
  };

  //#region ENVIAR DATA A LA API (metodo y validacion)
  const enviarInfo = () => {
    const { sistema, appMovil, soporte } = opinion;
    const { NetPromoterScore } = infoCustomer;
    setLoader(true);
    let respuestaValidada: boolean = validarRespuesta(opinion);

    if (respuestaValidada) {
      let respuestaNPS: replyNps = {
        applicationWebPoints: sistema.puntaje,
        applicationWebComment: sistema.comentario,
        applicationMobilePoints: appMovil.puntaje,
        applicationMobileComment: appMovil.comentario,
        supportPoints: soporte.puntaje,
        supportComment: soporte.comentario,
      };

      sendReply(
        NetPromoterScore.id,
        infoCustomer.externalId,
        respuestaNPS
      ).then(({ status }) => {
        if (status == 200) {
          setLoader(false);
          mensajeModal(
            false,
            true,
            "¡Genial!",
            "Tu respuesta fue enviada correctamente."
          );
          setOpinion(defaultValues.opinion);
          consultarInfo(hash.hash);
          handlePostMessage();
        } else {
          setLoader(false);
          mensajeModal(
            false,
            true,
            "¡Oops! Tuvimos un problema.",
            "Debes puntuar cada área para poder enviar la respuesta."
          );
        }
      });
    } else {
      setLoader(false);
      mensajeModal(
        false,
        true,
        "¡Oops! Tuvimos un problema.",
        "Debes puntuar cada área para poder enviar la respuesta."
      );
    }
  };

  //VALIDAR DATOS ENVIADOS
  const validarRespuesta = (opinion: OpinionProps): boolean => {
    let correcto: boolean = true;

    const { sistema, appMovil, soporte } = opinion;

    if (areas.sistema) {
      if (sistema.puntaje === null) {
        correcto = false;
      }
    }
    if (areas.appMovil) {
      if (appMovil.puntaje === null) {
        correcto = false;
      }
    }
    if (areas.soporte) {
      if (soporte.puntaje === null) {
        correcto = false;
      }
    }
    return correcto;
  };
  //#endregion

  const validarEncuesta = (res: customerInfo) => {
    let validacionCorrecta = true;
    let fechaHoy: any = new Date().setHours(0, 0, 0, 0);
    let startDate: any = new Date(res.NetPromoterScore.startDate).setHours(
      0,
      0,
      0,
      0
    );
    let expirationDate: any = new Date(
      res.NetPromoterScore.expirationDate
    ).setHours(0, 0, 0, 0);

    if (fechaHoy < startDate) {
      mensajeModal(true, false, "¡Oops!", "Esta encuesta aun no comienza.");
      validacionCorrecta = false;
    }
    if (fechaHoy > expirationDate) {
      mensajeModal(true, false, "¡Oops!", "Esta encuesta ha expirado.");
      validacionCorrecta = false;
    }
    if (res.replyDate !== null) {
      mensajeModal(
        true,
        false,
        "¡Oops!",
        "¡Esta encuesta ya fue respondida! Muchas gracias por colaborar con la búsqueda de la calidad en Fidel."
      );
      validacionCorrecta = false;
    }
    return validacionCorrecta;
  };

  const mensajeModal = (
    estadoError: boolean,
    estadoModal: boolean,
    titulo: string,
    texto: string
  ) => {
    setAbrirModal({
      ...abrirModal,
      estadoError,
      estadoModal,
      titulo,
      texto,
    });
  };

  
const handlePostMessage = () => {
  let props: any = window;
  // Verificar si el usuario está en un dispositivo móvil
  const isMobileDevice = /Mobi|Android/i.test(navigator.userAgent);
  // Verificar si el usuario está en un dispositivo iPhone
  const isiPhone = /iPhone/i.test(navigator.userAgent);

  if(isMobileDevice || isiPhone){
    props.ReactNativeWebView.postMessage("encuesta_completada");
  }
  else{
    window.parent.postMessage({
      'func': 'handleMessage',
      'message': 'encuesta_completada'
    }, "*");
  }
  
};

  return (
    <section className="background" style={{ position: "relative" }}>
      {loader && (
        <section className={`background ${classes.loaderNpsFondo}`}>
          <img
            src={fidelBlanco}
            alt="logo fidel"
            className={classes.loaderNpsFondoImg}
          />
          <Typography
            component="h1"
            variant="h3"
            textAlign={"center"}
            color={"white"}
            sx={{
              marginBottom: 5,
              marginTop: 5,
              minWidth: 340,
              fontSize: "1.7em",
            }}
          >
            ¡Soluciones en la Nube para potenciar tu Empresa!
          </Typography>
          <span className="loaderNps"></span>
        </section>
      )}
      <section className={classes.contenedor}>
        <section className={classes.presentacion}>
          <div
            style={{
              margin: "auto 0px",
            }}
          >
            <img
              src={fidelBlanco}
              className={classes.imagenLogoFidelNps}
              alt="logo fidel"
            />
          </div>
          <article>
            <span className={classes.tituloTextoNps}>
              ¡TU OPINIÓN VALE! Compartinos tu experiencia.
            </span>
          </article>
        </section>

        {/* WEB */}
        {areas.sistema && (
          <SectionScore
            titulo={"Sistema"}
            opinion={opinion}
            setOpinion={setOpinion}
            comentario={"el Sistema"}
            name={"sistema"}
          />
        )}

        {/* APP MOBILE */}
        {areas.appMovil && (
          <SectionScore
            titulo={"Aplicación Móvil"}
            opinion={opinion}
            setOpinion={setOpinion}
            comentario={"la Aplicación Móvil"}
            name={"appMovil"}
          />
        )}

        {/* ASESORIA */}
        {areas.soporte && (
          <SectionScore
            titulo={"Asesoría"}
            opinion={opinion}
            setOpinion={setOpinion}
            comentario={"el área de Asesoría"}
            name={"soporte"}
          />
        )}

        {abrirModal.estadoError && (
          <section className={classes.modalError}>
            <div
              style={{
                height: "50%",
              }}
            >
              <img
                src={ErrorNps}
                alt="error en encuesta"
                className={classes.modalErrorImg}
              />
            </div>
            <article className={classes.modalErrorInfo}>
              <p className={classes.modalErrorTexto}>{abrirModal.titulo}</p>

              <p className={classes.modalErrorTexto}>{abrirModal.texto}</p>
            </article>
          </section>
        )}
      </section>

      {(areas.soporte === true ||
        areas.appMovil === true ||
        areas.sistema === true) && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <input
            type="submit"
            value="Enviar Respuesta"
            className={classes.btnScoreEnviar}
            onClick={enviarInfo}
          />
        </div>
      )}

      <Modal
        open={abrirModal.estadoModal}
        onClose={() =>
          setAbrirModal({
            ...abrirModal,
            estadoModal: false,
          })
        }
        closeAfterTransition
      >
        <Fade in={abrirModal.estadoModal}>
          <Box className={classes.estilosModal}>
            <Typography variant="h6" component="h2">
              {abrirModal.titulo}
            </Typography>
            <Typography sx={{ mt: 2, fontSize: ".8em" }}>
              {abrirModal.texto}
            </Typography>
          </Box>
        </Fade>
      </Modal>
    </section>
  );
}
