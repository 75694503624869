import ejemplo from "./../../../img/contador.png";
import { useStyles } from "../../Socios/SociosForm/Socios.styles";
import { Grid, Typography, Divider } from "@mui/material";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import LinkOutlinedIcon from "@mui/icons-material/LinkOutlined";
import { IProps } from "./SociosForm.types";
import { ColorsCode } from "../../../utils/Colors/ColorsList";

export default function Socio({
  name: nombre,
  partnerType: empresa,
  description: descripcion,
  email,
  phoneArea,
  phoneNumber,
  address: direccion,
  web: url,
  imagen: imagen,
}: IProps) {
  const classes = useStyles();
  return (
    <Grid
      alignContent="center"
      sx={{ borderRadius: 2 }}
      className={classes.gridContainer}
    >
      <Grid
        container
        flexDirection="row"
        flexWrap="nowrap"
        width="100%"
        height="33%"
        display="flex"
      >
        <Grid
          item
          order="1"
          width="10%"
          marginBottom="0.5rem"
          marginTop="0.7rem"
        >
          <img
            src={`data:image/jpeg;base64,${imagen}`}
            alt=""
            className={classes.imagen}
          />
        </Grid>
        <Grid
          item
          order="2"
          width="90%"
          marginBottom="0.5rem"
          marginTop="1.5rem"
          textAlign="center"
        >
          <Typography
            className={classes.tituloSocios}
            style={{ fontWeight: "600" }}
            variant="h3"
            component="h1"
          >
            {empresa} - {nombre}
          </Typography>
        </Grid>
      </Grid>
      <Grid height="33%" overflow="auto">
        <Divider sx={{ ml: "10px", mr: "10px" }}></Divider>
        <Typography
          variant="subtitle1"
          component="h1"
          paddingRight="10px"
          paddingLeft="10px"
          paddingTop="5px"
          paddingBottom="5px"
          fontSize="13px"
          color={ColorsCode.grey}
        >
          {descripcion}
        </Typography>
        <Divider sx={{ ml: "10px", mr: "10px" }}></Divider>
      </Grid>

      <Grid className={classes.containerInfoSocios}>
        <Grid minHeight="60px !important">
          <Grid
            padding="0px 8px"
            margin="7px"
            className={classes.gridInfoSocios}
            display="flex"
            flexWrap="nowrap"
            flexDirection="row"
            gap="1px"
            justifyContent="center"
            alignItems="center"
            height="50px"
            minWidth="130px"
          >
            <LocalPhoneOutlinedIcon
              htmlColor={ColorsCode.orange}
              sx={{ marginBottom: "9px" }}
            />
            <Typography
              component="h1"
              marginLeft="10px"
              variant="subtitle2"
              fontSize="12px"
              color={ColorsCode.grey}
            >
              {phoneArea + " " + phoneNumber}
            </Typography>
          </Grid>
        </Grid>

        <Grid minHeight="60px !important" margin="auto">
          <Grid
            padding="0px 8px"
            margin="7px"
            className={classes.gridInfoSocios}
            display="flex"
            flexWrap="nowrap"
            flexDirection="row"
            gap="1px"
            justifyContent="center"
            alignItems="center"
            height="50px"
            minWidth="250px"
          >
            <HomeOutlinedIcon
              htmlColor={ColorsCode.orange}
              sx={{ marginBottom: "6px" }}
            />
            <Typography
              component="h1"
              variant="subtitle2"
              fontSize="12px"
              color={ColorsCode.grey}
            >
              {direccion}
            </Typography>
          </Grid>
        </Grid>

        <Grid minHeight="60px !important" margin="auto">
          <Grid
            padding="0px 8px"
            margin="7px"
            className={classes.gridInfoSocios}
            display="flex"
            flexWrap="nowrap"
            flexDirection="row"
            gap="1px"
            justifyContent="center"
            alignItems="center"
            height="50px"
            minWidth="230px"
          >
            <EmailOutlinedIcon
              htmlColor={ColorsCode.orange}
              sx={{ display: "flex" }}
            />
            <Typography
              component="h1"
              variant="subtitle2"
              display="flex"
              fontSize="12px"
              color={ColorsCode.grey}
            >
              {email}
            </Typography>
          </Grid>
        </Grid>

        <Grid minHeight="60px !important" marginLeft="auto" marginRight="2px">
          <Grid
            padding="0px 8px"
            margin="7px"
            className={classes.gridInfoSocios}
            display="flex"
            flexWrap="nowrap"
            flexDirection="row"
            gap="1px"
            justifyContent="center"
            alignItems="center"
            height="50px"
            minWidth="150px"
          >
            {url !== "" && <LinkOutlinedIcon htmlColor={ColorsCode.orange} />}
            <Typography
              component="h1"
              variant="subtitle2"
              display="flex"
              fontSize="12px"
              color={ColorsCode.grey}
            >
              {url}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
