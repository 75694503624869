import {
  Box,
  Button,
  CssBaseline,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { useStyles } from "./AuthCollaborator.styles";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import logoFidel from "./../../../img/fidelBlanco.png";
import { auth } from "../../../api/services/auth/auth.service";
import { errorHandling, setTokenVentas } from "../../../api";
import { AuthResponse } from "../../../api/services/auth/auth.types";
import { CollaboratorRequest } from "../../../api/services/collaborator/collaborator.types";
import { newColaborator } from "../../../api/services/collaborator/collaborator.service";
import { SnackBarProps } from "../../Lead/LeadForm/LeadForm.types";
import Snackbar from "../../../components/Snackbar/Snackbar";
import { CustomTextField } from "../../../common/CustomTextField";
import Loader from "../../../components/Loader/Loader";
import networkImage from "./../../../img/WorldNetwork.png";

interface Credentials {
  email: string;
  dni: string;
}

document.title = "Programa de Referidos";

const validationSchema = yup.object({
  email: yup
    .string()
    .email("Ingrese un Email válido")
    .required("Email requerido"),
  dni: yup
    .number()
    .typeError("Sólo se admiten caracteres numéricos")
    .min(4, "Ingrese un DNI válido")
    .required("DNI requerido"),
});

export default function AuthCollaborator() {
  const classes = useStyles();
  const navigate = useNavigate();
  const initialValues: Credentials = { email: "", dni: "" };
  const [loader, setLoader] = useState<boolean>(false);
  const [snackbar, setSnackbar] = useState<SnackBarProps>({} as SnackBarProps);

  const onSubmit = (values: Credentials) => {
    setLoader(true);
    auth(+values.dni, values.email)
      .then(async (data) => {
        if (data === 400) {
          createColaborator(values.email, +values.dni);
        } else if (data instanceof Object) {
          let responseData: AuthResponse = data.data!;
          setTokenVentas(responseData.token);

          let token = responseData.token;
          setLoader(false);
          navigate("/referir", { state: { token } });
        } else {
          setLoader(false);
          setSnackbar({
            open: true,
            msg: "Ha ocurrido un error inesperado",
            severity: "error",
          });
        }
      })
      .catch(errorHandling);
  };

  const createColaborator = (email: string, DNI: number) => {
    let colaborator: CollaboratorRequest = { email, DNI };

    setLoader(true);
    newColaborator(colaborator)
      .then(async (data) => {
        auth(DNI, email)
          .then(async (data) => {
            if (data instanceof Object) {
              let responseData = data.data;
              setTokenVentas(responseData!.token);

              let token = responseData!.token;
              setLoader(false);
              navigate("/referir", { state: { token } });
            } else {
              setLoader(false);
              setSnackbar({
                open: true,
                msg: "Ha ocurrido un error inesperado",
                severity: "error",
              });
            }
          })
          .catch(errorHandling);
      })
      .catch(errorHandling);
  };

  const handleSnackbar = () => {
    if (snackbar) setSnackbar({} as SnackBarProps);
  };

  const goToTYC = () => {
    navigate("/tyc");
  };

  return (
    <>
      {loader && <Loader />}
      <Grid container component="main">
        <CssBaseline />
        <Grid
          item
          xs={12}
          sm={8}
          md={5}
          component={Paper}
          elevation={3}
          square
          className={`${classes.containerForm} background`}
        >
          <Box className={classes.formBox}>
            <div className={classes.logoContainer}>
              <a href="https://fidel.com.ar" target="_BLANK">
                <img src={logoFidel} height="50" alt="" />
              </a>
            </div>
            <Typography
              component="h1"
              variant="h3"
              textAlign={"center"}
              color={"white"}
              sx={{ marginBottom: 10 }}
            >
              ¡Soluciones en la Nube para potenciar tu Empresa!
            </Typography>
            <Typography
              component="h4"
              sx={{ fontSize: "1.05em", textAlign: "center" }}
              color={"white"}
            >
              Ingresá sólo con tu email y DNI y ya podés cargar los datos del
              interesado.
            </Typography>
            <Box sx={{ mt: 1 }}>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {({ values, errors, touched, handleChange }) => (
                  <Form>
                    <CustomTextField
                      id="email"
                      name="email"
                      label="Email"
                      error={touched.email && Boolean(errors.email)}
                      autoFocus
                    />
                    <CustomTextField
                      id="dni"
                      name="dni"
                      label="DNI"
                      error={touched.dni && Boolean(errors.dni)}
                    />
                    <Button
                      fullWidth
                      type="submit"
                      variant="contained"
                      sx={{
                        mt: 3,
                        backgroundColor: "#d17a29",
                        "&:hover": {
                          backgroundColor: "#d17a29",
                        },
                      }}
                    >
                      Ingresar
                    </Button>
                    <div
                      style={{
                        width: "100%",
                        textAlign: "center",
                        marginTop: "20px",
                      }}
                    >
                      <Button
                        variant="text"
                        sx={{ color: "#ffff" }}
                        onClick={goToTYC}
                      >
                        Términos y condiciones
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          className={classes.gridContainer}
          sx={{ flexDirection: "column" }}
        >
          <article>
            <Typography
              component="h1"
              variant="h4"
              textAlign={"center"}
              color={"gray"}
              sx={{ fontWeight: 700, marginTop: 5 }}
            >
              ¡Sumate a nuestra Red de Influenciadores!
            </Typography>
            <Typography
              paragraph={true}
              color={"green"}
              textAlign={"center"}
              sx={{ marginTop: 2, fontSize: 19 }}
            >
              Ayudanos a hacer crecer la comunidad de Fidel y ganá un bono de
              $10.000... <br /> ¡Sólo tenés que cargar los datos del referido!
            </Typography>
          </article>
          <figure className={classes.contImgNetwork}>
            <img
              src={networkImage}
              alt=""
              style={{ width: "100%", height: "100%", objectFit: "contain" }}
            />
          </figure>
        </Grid>
        {snackbar.open && (
          <Snackbar
            msg={snackbar.msg}
            severity={snackbar.severity}
            handleSnack={handleSnackbar}
          />
        )}
      </Grid>
    </>
  );
}
