import { PARTNERS, PARTNERS_TYPES } from "../../constants";
import { ApiMarketing, errorHandling } from "../../api.Marketing";
import { Item, PartnersRequest, PartnersResponse } from "./partners.types";
import { ApiResponse } from "../types";
import { IList } from "../../../common/Selects/AsyncSelect";

export const getPartners = async (
  params?: PartnersRequest
): Promise<ApiResponse<PartnersResponse>> => {
  return await ApiMarketing.get<PartnersResponse>(PARTNERS, { params })
    .then((res) => ({
      result: Boolean(res.data),
      data: res.data,
      status: res.status,
    }))
    .catch(errorHandling);
};

export const getPartnersTypes = async (): Promise<ApiResponse<IList[]>> => {
  return await ApiMarketing.get<Item[]>(PARTNERS_TYPES)
    .then((res) => ({
      result: Boolean(res.data),
      data: res.data.map((x) => ({ value: x.id, text: x.name })),
      status: res.status,
    }))
    .catch(errorHandling);
};
