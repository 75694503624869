import { useStyles } from "./../../pages/Nps/NpsScore/NpsScore.styles";
import { Props } from "../../pages/Nps/NpsInterfaces/NpsInterfaces.types";
export const SectionScore = (props: Props )=>{
    const classes = useStyles();
    const {titulo, comentario, opinion, setOpinion, name} = props;

    const puntuar = (e:any)=>{

        let classListButtonSelected = e.target.classList

        const allButtons = Array.from(document.getElementsByClassName(`${name}`));
        allButtons.map(btn=>{
            btn.classList.remove(`${classes.selectedStyles}`);
        })
            classListButtonSelected.add(`${classes.selectedStyles}`);
           
       
        setOpinion({...opinion,
        [name]: {
            ...opinion[name],
            puntaje: +e.target.value
        }
        });
    };

    const comentar = (e:any)=>{
        setOpinion({...opinion,
        [name]: {
            ...opinion[name],
            comentario: e.target.value
        }
        });
    };

    
    return (
			<>
				<h2 className={classes.tituloSeccion}>{titulo}</h2>

				<section className={classes.seccionesScore}>
					<section className={classes.contScore}>
						<div>
							<h4 className={classes.tituloScore} style={{ marginBottom: 2 }}>
								Basado en tu experiencia...
							</h4>
							<h4 className={classes.tituloScore} style={{ marginTop: 4 }}>
								En una escala del 1 al 10. ¿Qué nivel de satisfacción tenés con{' '}
								{comentario}?
							</h4>
							<div className={classes.contListaBtn}>
								<div className={classes.contBtnScore}>
									<input
										name='malo'
										onClick={(e: any) => {
											puntuar(e);
										}}
										className={`${classes.btnScoreMalo}    ${name}   ${classes.btnScore}`}
										value={1}
										type='button'
									></input>
									<input
										name='malo'
										onClick={(e: any) => {
											puntuar(e);
										}}
										className={`${classes.btnScoreMalo}    ${name}   ${classes.btnScore}`}
										value={2}
										type='button'
									></input>
									<input
										name='malo'
										onClick={(e: any) => {
											puntuar(e);
										}}
										className={`${classes.btnScoreMalo}    ${name}   ${classes.btnScore}`}
										value={3}
										type='button'
									></input>
									<input
										name='malo'
										onClick={(e: any) => {
											puntuar(e);
										}}
										className={`${classes.btnScoreMalo}    ${name}   ${classes.btnScore}`}
										value={4}
										type='button'
									></input>
									<input
										name='bueno'
										onClick={(e: any) => {
											puntuar(e);
										}}
										className={`${classes.btnScoreBueno}  ${name}    ${classes.btnScore}`}
										value={5}
										type='button'
									></input>
									<input
										name='bueno'
										onClick={(e: any) => {
											puntuar(e);
										}}
										className={`${classes.btnScoreBueno}   ${name}   ${classes.btnScore}`}
										value={6}
										type='button'
									></input>
									<input
										name='bueno'
										onClick={(e: any) => {
											puntuar(e);
										}}
										className={`${classes.btnScoreBueno}   ${name}   ${classes.btnScore}`}
										value={7}
										type='button'
									></input>
									<input
										name='mBueno'
										onClick={(e: any) => {
											puntuar(e);
										}}
										className={`${classes.btnScoreMBueno} ${name}    ${classes.btnScore}`}
										value={8}
										type='button'
									></input>
									<input
										name='mBueno'
										onClick={(e: any) => {
											puntuar(e);
										}}
										className={`${classes.btnScoreMBueno}   ${name}  ${classes.btnScore}`}
										value={9}
										type='button'
									></input>
									<input
										name='excelente'
										onClick={(e: any) => {
											puntuar(e);
										}}
										className={`${classes.btnScoreExcelente} ${name} ${classes.btnScore}`}
										value={10}
										type='button'
									></input>
								</div>
								<span className={classes.leyendaScore}>Muy Mala</span>
								<span
									style={{ textAlign: 'right' }}
									className={classes.leyendaScore}
								>
									Muy Buena
								</span>
							</div>
						</div>

						<section className={classes.comentarioSeccion}>
							<span className={classes.subtituloComentario}>
								Dejanos un comentario (opcional)
							</span>
							<textarea
								className={classes.textAreaScore}
								placeholder='Comentario...'
								style={{ resize: 'none' }}
								maxLength={255}
								onChange={(msg: any) => comentar(msg)}
							/>
						</section>
					</section>
				</section>
			</>
		);
}