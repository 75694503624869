import { ApiResponse } from "../types";
import { AUTH } from "../../constants";
import { AuthError, AuthResponse } from "./auth.types";
import { ventasApi } from "../../api";

export const auth = async (dni:number, email:string ): Promise<ApiResponse<AuthResponse> | number> => {
  return await ventasApi
    .post<AuthResponse>( AUTH, {DNI:dni, email})
    .then((res) => {
      return {
        result: Boolean(res.data),
        data: res.data,
        status: res.status,
      };
    })
    .catch(err => {
      let error:AuthError = JSON.parse(JSON.stringify(err));
      if(error.status === 400){
        return error.status;
      }
      return 500;
    });
};
