import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
    container:{
        minHeight: '80vh',
        width:"90%",
        margin:"auto",
    },
    title:{
        fontWeight:"500",
        ['@media (max-width:700px)']: {
            fontSize:"20px",
        },
        textAlign:"center",
        paddingTop:"20px"
    },
    headersTable:{
        maxWidth: "250px",
        backgroundColor: "rgba(149 195 156)"
    },
    cellsTable:{
        maxWidth: "250px",
    },
    arrowBackButton:{
        ['@media (max-width:700px)']: {
            bottom:"31px",
            right:"10px",
        },
        color:"#222",
        alignSelf:"flex-start",
        bottom:"42px",
    },
    arrowIcon:{
        color: "black",
        ['@media (max-width:700px)']: {
            fontSize:"25px",
        },
        fontSize:"30px",
    },
});
  