import { CITIES } from "../../constants";
import { City } from "../../../pages/Socios/SociosForm/SociosForm.types";
import { ApiResponse } from "../types";
import { ApiMarketing, errorHandling } from "../../api.Marketing";
import { IList } from "../../../common/Selects/AsyncSelect";

export const getCitySelect = async (
  InputValue: string
): Promise<ApiResponse<IList[]>> => {
  return await ApiMarketing.get<City[]>(`${CITIES}`, {
    params: { name: InputValue },
  })
    .then((res) => ({
      result: Boolean(res.data),
      data: res.data.map((x) => ({ value: x.id, text: x.name })),
      status: res.status,
    }))
    .catch(errorHandling);
};
