import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
    dataCollaborator:{
        display: "flex",
        justifyContent:"space-between",
        width:"100%",
        alignSelf: "start",
        marginBottom:"20px",
        ['@media (max-width:700px)']: {
            flexDirection: 'column'
        }
    },
    textDataCollaborator:{
        color: "#fff",
        wordBreak:"break-word",
        padding:0,
        margin:0,
    },
    textName:{
        color: "#fff",
        wordBreak:"break-word",
        padding:0,
        marginTop:2,
        fontSize:"20px",
    },
    buttonProfile:{
        display:"flex",
        flexDirection: "row",
        justifyContent: "center",
        width: "max-content",
        ['@media (max-width:700px)']: {
            marginTop:"10px",
            textAlign:"right"
        },
        ['@media (min-width: 1024px)']:{
            flexDirection: "column",
        },
        float: "right",
    },
    buttonList:{
        ['@media (max-width:700px)']: {
            textAlign:"right"
        },
        float: "right",
    },
    formBox:{
        margin:"30px",
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    gridContainer:{
        display: "flex",
        width: "100% !important",
        flexBasis: "unset !important",
        ["@media (max-width: 912px)"]:{
            maxWidth: "100% !important"
        },
        ["@media (min-height: 800px) and (min-width: 1280px)"]:{
            height: "900px"
        }
        },
        contImgNetwork: {
            width: "80%",
            height:"80%",
            margin: "auto"
        },
    errorMessage: {
        color:"#d32f2f",
    },
    select:{
        backgroundColor: "#fff",
    },
    arrowBackButton:{
        alignSelf:"flex-start",
        fontSize:"25px",
        position:"relative",
        top:"16px",
    },
    location: {
        '& label.Mui-focused': {
            color: '#d17a29',
          },
          '& .MuiFilledInput-root:after': {
            borderBottomColor: '#d17a29',
          },
          "& .css-1gzkxga-MuiSelect-select-MuiInputBase-input-MuiFilledInput-input": {
            background: "#ffff !important"
          },
    },
    containerForm: {
        ["@media (max-width: 820px)"]: {
            flexBasis: "unset !important",
            maxWidth: "100% !important"
        },
    },
    btnLead: {
        backgroundColor: "#d17a29 !important",
        marginRight: "2px !important",
        color: "#ffff !important",
        '&:hover': {
            backgroundColor: '#d17a29 !important',
            color: "#ffff !important"
          },
        ["@media (min-width: 1024px)"]: {
                margin: "4px 0  !important"
        }
    }
});
  