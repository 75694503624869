//#region interfaces
export interface Props {
  titulo: string;
  comentario: string;
  opinion: any;
  setOpinion: Function;
  name: string;
}
export interface AreasProps {
  sistema?: boolean;
  appMovil?: boolean;
  soporte?: boolean;
}
export interface OpinionProps {
  sistema: {
    comentario: string | null;
    puntaje: number | null;
  };
  appMovil: {
    comentario: string | null;
    puntaje: number | null;
  };
  soporte: {
    comentario: string | null;
    puntaje: number | null;
  };
}
export interface hashParam {
  hash: string;
}
export interface InfoModal {
  estadoError: boolean;
  estadoModal: boolean;
  titulo: string;
  texto: string;
}
export interface InfoCustomer {
  id: number;
  externalId: number;
  accountId: number;
  NetPromoterScore: {
    id: number;
    name: string;
    startDate: Date;
    endDate: Date;
    expirationDate: Date;
    applicationId: number;
  };
}
//#endregion
//#region valores por defecto
export const defaultValues = {
  opinion: {
    sistema: {
      comentario: null,
      puntaje: null,
    },
    appMovil: {
      comentario: null,
      puntaje: null,
    },
    soporte: {
      comentario: null,
      puntaje: null,
    },
  },
  infoModal: {
    estadoError: false,
    estadoModal: false,
    titulo: "",
    texto: "",
  },
};
//#endregion
