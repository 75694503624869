import { Grid } from "@mui/material";
import { Container } from "@mui/system";
import logoTrizap from "./../../img/trizapLogo.png";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import LanguageIcon from "@mui/icons-material/Language";
import "./Footer.styles";
import { useStyles } from "./Footer.styles";

export default function Footer() {
  let date: Date = new Date();
  const classes = useStyles();

  return (
    <>
      <Container
        maxWidth={false}
        className={classes.container}
        sx={{ padding: "0 !important" }}
      >
        <Grid
          container
          spacing={2}
          className={classes.gridTable}
          sx={{ padding: "20px 8% !important" }}
        >
          <Grid item md={8} xs={12} className={classes.gridLogo}>
            <div className={classes.logosContainer}>
              <a href="https://trizap.net" target="_BLANK">
                <img
                  src={logoTrizap}
                  height="60"
                  alt="Trizap"
                  style={{ marginTop: "4px" }}
                />
              </a>
            </div>
            <p style={{ fontSize: ".8rem", lineHeight: 1.5 }}>
              El sistema{" "}
              <a
                href="https://www.fidel.com.ar/"
                target="_BLANK"
                style={{ color: "#ffff" }}
              >
                FIDEL
              </a>
              , es propiedad de la Empresa de Software{" "}
              <a
                href="http://trizap.net/"
                target="_BLANK"
                style={{ color: "#ffff" }}
              >
                TRIZAP
              </a>
              .
            </p>
            <p style={{ fontSize: ".8rem", lineHeight: 1.5 }}>
              Fidel es un conjunto de Soluciones en la Nube para potenciar tu
              Empresa que te permitirán tener el control de toda la información
              de tu negocio en un lugar centralizado y seguro para acceder desde
              cualquier ubicación en el planeta.
            </p>
            <p style={{ fontSize: ".8rem", lineHeight: 1.5 }}>
              Desde la Aplicación Web de Gestión de los datos de tu Negocio
              hasta una App Móvil para la toma de Pedidos de un Vendedor y hasta
              un Canal de Ventas para que tus Clientes realicen compras o para
              captar nuevos potenciales compradores.
            </p>
          </Grid>
          <Grid item md={3} xs={12} className={classes.contactContainer}>
            <div>
              <span>CONTACTO</span>
              <div className={classes.linksFooter}>
                <EmailIcon />
                <a
                  href="mailto: info@fidel.com.ar"
                  style={{
                    textDecoration: "none",
                    color: "#fff",
                    fontSize: 12,
                  }}
                >
                  <span style={{ marginLeft: "5px" }}>info@fidel.com.ar</span>
                </a>
              </div>
              <div className={classes.linksFooter}>
                <PhoneIphoneIcon />
                <a
                  href="tel: 54 3564 15506534"
                  style={{
                    textDecoration: "none",
                    color: "#fff",
                    fontSize: 12,
                  }}
                >
                  <span style={{ marginLeft: "5px" }}>+54 3564 15506534</span>
                </a>
              </div>
              <div className={classes.linksFooter}>
                <LanguageIcon />
                <a
                  href="https://fidel.com.ar"
                  target="_BLANK"
                  style={{
                    textDecoration: "none",
                    color: "#fff",
                    fontSize: 12,
                  }}
                >
                  <span style={{ marginLeft: "5px" }}>fidel.com.ar</span>
                </a>
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>

      <Container maxWidth={false} className={classes.bottomContainer}>
        <p className={classes.copyrightText}>
          {date.getFullYear()} ©Trizap. Todos los derechos reservados.
        </p>
      </Container>
    </>
  );
}
