import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  input:{
    backgroundColor: "#fff",
    borderRadius:"4px 4px 0 0",
    '& label.Mui-focused': {
      color: '#d17a29',
    },
    '& .MuiFilledInput-root:after': {
      borderBottomColor: '#d17a29',
    },
  },
  errorMessage: {
      color:"#d32f2f",
  },
});