import { makeStyles } from "@mui/styles";
import { ColorsCode } from "../../../utils/Colors/ColorsList";

export const useStyles = makeStyles({
  imagen: {
    paddingBottom: "8px",
    paddingTop: "8px",
  },
  gridSelect: {
    backgroundColor: ColorsCode.light_grey,
  },
  selectContainer: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    ["@media (max-width:1000px)"]: {
      flexDirection: "column",
      display: "inline",
      flexWrap: "nowrap",
      alignContent: "stretch",
    },
  },
  selectField: {
    display: "flex",
    width: "100%",
    margin: "0px 24px 0px 0px",
    ["@media (max-width:1000px)"]: {
      margin: "6px 0px",
    },
  },
  formContainer: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    padding: "15px 0px",
    margin: "0 8%",
    ["@media (max-width:1000px)"]: {
      flexDirection: "column",
      display: "inline",
    },
  },

  form: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-evenly",
  },
  location: {
    "& label.Mui-focused": {
      color: ColorsCode.orange,
    },
    "& .MuiFilledInput-root:after": {
      borderBottomColor: ColorsCode.orange,
    },
    "& .css-1gzkxga-MuiSelect-select-MuiInputBase-input-MuiFilledInput-input": {
      background: `${ColorsCode.white} !important`,
    },
  },
  input: {
    top: 2,
    paddingBottom: 6,
    height: 45,
    width: "100% !important",
    backgroundColor: `${ColorsCode.white} !important`,
    color: `${ColorsCode.grey} !important`,
    borderRadius: "4px 4px 0 0 !important",
    "& label.Mui-focused": {
      color: `${ColorsCode.orange} !important`,
      lineHeight: "1rem !important",
    },
    "& label": { lineHeight: "1rem !important" },
    "& .MuiFilledInput-root:after": {
      borderBottomColor: `${ColorsCode.orange} !important`,
    },
    "&:hover, &:focus": { background: `${ColorsCode.white} !important` },
  },
  buttonForm: {
    backgroundColor: `${ColorsCode.orange} !important`,
    "&:hover": {
      backgroundColor: `${ColorsCode.orange}!important`,
    },
    ["@media (max-width:1000px)"]: {
      width: "100%",
    },
  },
  selectForm: {
    backgroundColor: `${ColorsCode.white} !important`,
    borderRadius: "4px 4px 0 0 !important",
    overflow: "hidden",
    "&:hover, &:focus !important": {
      background: `${ColorsCode.white} !important`,
    },
  },
  textField: {
    width: "100%",
    backgroundColor: "white",
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    "& label.Mui-focused": {
      color: `${ColorsCode.orange} !important`,
    },
    "& .MuiFilledInput-root:after": {
      borderBottomColor: `${ColorsCode.orange} !important`,
    },
  },
});
