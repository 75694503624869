import * as React from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState } from 'react';
import { Alert } from '@mui/material';

interface Props{
    msg:string,
    severity: "error" | "warning" | "info" | "success",
    handleSnack: () => void;
}

export const SimpleSnackbar = ({msg, severity, handleSnack}:Props) => {
    const [open, setOpen] = useState(false);

    useEffect(()=>{
        setOpen(true);
    },[])

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') return;

        setOpen(false);
        handleSnack();
    };

    const action = (
        <>
            <Button color="secondary" size="small" onClick={handleClose}/>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </>
    );

    return (
        <div>
            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                action={action}
                anchorOrigin={{ vertical:"top", horizontal:"center" }}
            >
                <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
                    {msg}
                </Alert>
            </Snackbar>
        </div>
    );
}

export default SimpleSnackbar;