import { errorHandling, ventasApi } from "../../api";
import { LEAD } from "../../constants";
import { ApiResponse } from "../types";
import { Lead, LeadError, LeadInfoResponse, LeadResponse } from "./leads.types";

export const newLead = async (lead:Lead ): Promise<ApiResponse<LeadResponse> | number> => {
  return await ventasApi
    .post<LeadResponse>( LEAD, lead)
    .then((res) => {
      return {
        result: Boolean(res.data),
        data: res.data,
        status: res.status,
      };
    })
    .catch(err => {
      let error:LeadError = JSON.parse(JSON.stringify(err));
      if(error.status === 400){
        return error.status;
      }
      return 500;
    });
};

export const getLeads = async (collaboratorId?:number ): Promise<ApiResponse<LeadInfoResponse[]>> => {
  return await ventasApi
    .get<LeadInfoResponse[]>( `${LEAD}?collaboratorId=${collaboratorId}` )
    .then((res) => {
      return {
        result: Boolean(res.data),
        data: res.data,
        status: res.status,
      };
    })
    .catch(errorHandling)
};