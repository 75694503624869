import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
    gridContainer:{
    display: "flex",
    width: "100% !important",
    flexBasis: "unset !important",
    ["@media (max-width: 912px)"]:{
        maxWidth: "100% !important"
    },
    ["@media (min-height: 800px) and (min-width: 1280px)"]:{
        height: "900px"
    }
    },
    contImgNetwork: {
        width: "80%",
        height:"80%",
        margin: "auto"
    },
    formBox:{
        margin:"30px",
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    logoContainer:{
        paddingBottom:"30px",
        paddingTop:"30px"
    },
    errorMessage: {
        color:"#d32f2f",
    },
    generalContainer: {
        ["@media (max-width: 820px)"]: {
            flexDirection: "column !important",
        },
    },
    containerForm: {
        ["@media (max-width: 912px)"]: {
            flexBasis: "unset !important",
            maxWidth: "100% !important"
        },
    }
});
  