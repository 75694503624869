import axios from "axios";
import { ApiResponse } from "./services/types";

export const ventasApi = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Accept: "application/json",
  },
});

export const errorHandling = (err: any): ApiResponse<any> => {
  let response = "";

  if (err) {
    response = err.response.data.error_description;
  }

  return { result: false, message: response };
};

export const setTokenVentas = (token: string) => {
  ventasApi.defaults.headers.common["Authorization"] = "Bearer " + token;
};
