import React from "react";

export type Colors =
  | "orange"
  | "green"
  | "blue"
  | "grey"
  | "white"
  | "black"
  | "light_grey"
  | "boldgrey"
  | "dark_white"
  | "dark_green";

export const ColorsCode: Record<Colors, string> = {
  orange: "#d17a29",
  green: "#82b68a",
  dark_green: "#5a825f",
  blue: "#44d",
  grey: "#4A4A4A",
  light_grey: "#bac2b8",
  boldgrey: "#ccc",
  dark_white: "#e5ebd7",
  white: "#fff",
  black: "#000",
};
