import axios from "axios";
import { ApiResponse } from "./services/types";

export const ApiMarketing = axios.create({
    baseURL: process.env.REACT_APP_API_MARKETING,
    headers: {
      Accept: "application/json",
    },
});

export const errorHandling = (err: any): ApiResponse<any> => {
  let response = "";

  if (err) {
    response = err.response.data.error_description;
  }

  return { result: false, message: response, status: err.status };
};

export const generateQueryParams = (data: any) => {
  let params = "";
  for (const key in data) {
    if (data[key] !== undefined) {
      params += (params ? "&" : "?") + key + "=" + data[key];
    }
  }
  return params;
};
