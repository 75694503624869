import { Navigate, Route, Routes } from "react-router-dom";
import { TYCDocument } from "./TYC/TYCDocument";
import SociosForm from "./Socios/SociosForm/SociosForm";
import AuthCollaborator from "./Collaborator/AuthCollaborator/AuthCollaborator";
import LeadForm from "./Lead/LeadForm/LeadForm";
import { LeadList } from "./List/LeadList/LeadList";
import NpsScore from "./Nps/NpsScore/NpsScore";
import ProfileForm from "./Profile/ProfileForm/ProfileForm";

export const Main = () => {
  return (
    <>
      <Routes>
        {/* REFERIR */}
        <Route path="/recomendanos" element={<AuthCollaborator />} />
        <Route path="/referir" element={<LeadForm />} />
        <Route path="/perfil" element={<ProfileForm />} />
        <Route path="/tyc" element={<TYCDocument />} />
        <Route path="/referidos" element={<LeadList />} />
        <Route path="*" element={<Navigate to="/recomendanos" />} />
        {/* NPS - PUNTUAR */}
        <Route path="/nps">
          <Route path=":hash" element={<NpsScore />} />
        </Route>
        <Route path="/socios" element={<SociosForm />} />
      </Routes>
    </>
  );
};
