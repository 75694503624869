import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import jwt_decode from "jwt-decode";
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button, CssBaseline, TablePagination, Typography } from '@mui/material';
import Grid from '@mui/material/Grid/Grid';
import { useEffect, useState } from 'react';
import { getLeads } from '../../../api/services/leads/leads.service';
import { setTokenVentas } from '../../../api';
import { useLocation, useNavigate } from 'react-router-dom';
import { LeadInfoResponse } from '../../../api/services/leads/leads.types';
import { useStyles } from './LeadList.styles';
import { LeadStatus } from '../../../utils/enums';
import * as Utils from '../../../utils/utils';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


export function LeadList() {
    const location = useLocation();
    const classes = useStyles();
    const navigate = useNavigate();
    let params = location.state;

    const [leads, setLeads] = useState<LeadInfoResponse[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    useEffect(()=>{
        setTokenVentas(params.token);
        setIsLoading(true);

        //Hago decode del token para obtener el id del colaborador
        var decoded:any = jwt_decode(params.token);
        let collaboratorId: number = +decoded['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/sid']; //FIXME: Revisar el nombre de la variable que viene de la API

        getLeads(collaboratorId).then(async(data)=>{
            let leadInfo = data.data;
            if(leadInfo){
                setIsLoading(false);
                setLeads(leadInfo)
            }
        }).catch((err)=>setIsLoading(false));
    },[]);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const returnToCredentials = () => {
        let token = params.token;
        navigate("/referir", { state: {token} });
    }

    return (
        <Grid className={classes.container} sx={{pt:3}}>
            <Typography variant='h4' className={classes.title}>Referidos</Typography>
            <Button onClick={returnToCredentials} className={classes.arrowBackButton}><ArrowBackIcon className={classes.arrowIcon}/></Button>
            <TableContainer component={Paper}>
            <CssBaseline/>
            <Table aria-label="simple table">
                <TableHead>
                <TableRow>
                    <TableCell className={classes.headersTable} sx={{textAlign:"center"}}><span style={{fontWeight:"bold", color: "#ffff"}}>#</span></TableCell>
                    <TableCell className={classes.headersTable}><span style={{fontWeight:"bold", color: "#ffff"}}>Empresa</span></TableCell>
                    <TableCell className={classes.headersTable}><span style={{fontWeight:"bold", color: "#ffff"}}>Email</span></TableCell>
                    <TableCell className={classes.headersTable} sx={{textAlign:"center"}}><span style={{fontWeight:"bold", color: "#ffff"}}>DNI/CUIT</span></TableCell>
                    <TableCell className={classes.headersTable} sx={{textAlign:"center"}}><span style={{fontWeight:"bold", color: "#ffff"}}>Teléfono</span></TableCell>
                    <TableCell className={classes.headersTable}><span style={{fontWeight:"bold", color: "#ffff"}}>Ciudad</span></TableCell>
                    <TableCell className={classes.headersTable} sx={{textAlign:"center"}}><span style={{fontWeight:"bold", color: "#ffff"}}>Estado</span></TableCell>
                    <TableCell className={classes.headersTable} sx={{textAlign:"center"}}><span style={{fontWeight:"bold", color: "#ffff"}}>Fecha de Registro</span></TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {leads && leads.length > 0 ? leads.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                    <TableRow
                    key={row.companyName}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell sx={{textAlign:"center"}}>{index + 1 + (page * rowsPerPage)}</TableCell>
                        <TableCell className={classes.cellsTable}>{row.companyName}</TableCell>
                        <TableCell className={classes.cellsTable}>{row.companyEmail}</TableCell>
                        <TableCell className={classes.cellsTable} sx={{textAlign:"center"}}>{row.companyCUIT ?? row.companyDNI}</TableCell>
                        <TableCell className={classes.cellsTable} sx={{textAlign:"center"}}>{row.phone}</TableCell>
                        <TableCell className={classes.cellsTable}>{row.city.name}</TableCell>
                        <TableCell className={classes.cellsTable} sx={{textAlign:"center"}}>{LeadStatus[row.status]}</TableCell>
                        <TableCell className={classes.cellsTable} sx={{textAlign:"center"}}>{Utils.convertToLocaleDate(row.registeredDate)}</TableCell>
                    </TableRow>
                    )) : isLoading ? (
                        <TableRow>
                            <TableCell colSpan={12} sx={{textAlign:"center"}}>
                                Cargando...
                            </TableCell>
                        </TableRow> ) : (
                        <TableRow>
                            <TableCell colSpan={12} sx={{textAlign:"center"}}>
                                Aún no has referido a ninguna empresa
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={[5, 10, 20]}
                component="div"
                count={leads.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={"Referidos por Página"}
                labelDisplayedRows={({ from, to, count })=>{return `${from}–${to} de ${count !== -1 ? count : `mas de ${to}`}` }}
                sx={{backgroundColor:"rgba(244,244,244,1)"}}
            />
            </TableContainer>
        </Grid>
    );
}