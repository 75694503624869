import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
    gridContainer:{
        display: "flex",
        width: "100% !important",
        flexBasis: "unset !important",
        ["@media (max-width: 912px)"]:{
            maxWidth: "100% !important"
        },
        ["@media (min-height: 800px) and (min-width: 1280px)"]:{
            height: "900px"
        }
        },
    contImgNetwork: {
        width: "80%",
        height:"80%",
        margin: "auto"
    },
    containerForm: {
        ["@media (max-width: 820px)"]: {
            flexBasis: "unset !important",
            maxWidth: "100% !important"
        },
    },
    formBox:{
        margin:"30px",
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    logoContainer:{
        paddingBottom:"30px",
        paddingTop:"30px"
    },
    errorMessage: {
        color:"#d32f2f",
    },
    arrowBackButton:{
        alignSelf:"flex-start",
        fontSize:"25px",
        position:"relative",
        top:"16px",
    },
    textField:{
        backgroundColor: "#fff",
        borderRadius:"4px 4px 0 0",
    }
});
  