export const convertToLocaleDate = (dateParam: string | Date): string => {
    let date:Date;

    if(typeof dateParam == "string" ){
        date = new Date(dateParam)
    }else{
        date = dateParam;
    }

    const formattedDate = date.toLocaleDateString("es-ES", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric"
    });

    return formattedDate;
}