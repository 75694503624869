import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  container: {
    backgroundColor: "rgba(0, 0, 0, 0.9)",
    color: "#fafafa",
  },
  contactContainer: {
    flexDirection: "column",
    alignSelf: "center",
    padding: "0 !important",
  },
  gridTable: {
    justifyContent: "space-between",
  },
  gridLogo: {
    textAlign: "left",
  },
  griditem: {
    margin: "auto",
  },
  bottomContainer: {
    backgroundColor: "rgba(0, 0, 0, 1)",
    color: "#fafafa",
    textAlign: "center",
    margin: "auto",
    borderTop: "1px solid #444",
  },
  copyrightText: {
    margin: 0,
    padding: "15px",
    fontSize: ".85em",
  },
  linksFooter: {
    display: "flex",
    alignItems: "center",
    height: "40px",
    ["@media (max-width:1000px)"]: {
      justifyContent: "center",
    },
  },
  logosContainer: {
    display: "flex",
    justifyContent: "space-between",
    ["@media (max-width:600px)"]: {
      flexDirection: "column",
    },
  },
});
