import { errorHandling, ventasApi } from "../../api";
import { COLABORATORS } from "../../constants";
import { ApiResponse } from "../types";
import { CollaboratorData, CollaboratorRequest, CollaboratorResponse } from "./collaborator.types";

export const getColaborator = async (collaboratorId:number ): Promise<ApiResponse<CollaboratorData>> => {
  return await ventasApi
    .get<CollaboratorData>( `${COLABORATORS}/${collaboratorId}`)
    .then((res) => {
      return {
        result: Boolean(res.data),
        data: res.data,
        status: res.status,
      };
    })
    .catch(errorHandling);
};

export const newColaborator = async (collaborator:CollaboratorRequest ): Promise<ApiResponse<CollaboratorResponse>> => {
  return await ventasApi
    .post<CollaboratorResponse>( COLABORATORS, collaborator)
    .then((res) => {
      return {
        result: Boolean(res.data),
        data: res.data,
        status: res.status,
      };
    })
    .catch(errorHandling);
};

export const updateColaborator = async (collaborator:CollaboratorRequest, collaboratorId:number ): Promise<ApiResponse<string> | number> => {
  return await ventasApi
    .put<string>( `${COLABORATORS}/${collaboratorId}`, collaborator)
    .then((res) => {
      return 200;
    })
    .catch(errorHandling);
};