import * as yup from 'yup';
import { LeadFormData } from './LeadForm.types';

export const validationSchema = yup.object({
    name: yup.string().required('Nombre requerido'),
    lastName: yup.string().required('Apellido requerido'),
    socialReason: yup.string().required('Razón social requerida'),
    email: yup.string().email('Ingrese un email válido').required('Email requerido'),
    phone: yup.number().typeError('Sólo se admiten caracteres numéricos').required('Número de teléfono requerido'),
    dni: yup.number().typeError('Sólo se admiten caracteres numéricos').required('DNI o CUIT requerido'),
    province: yup.number().min(1, "Provincia requerida").required("Provincia requerida"),
    locality: yup.number().min(1, "Localidad requerida").required("Localidad requerida"),
});

export const initialValues: LeadFormData = { name: '', lastName: '', socialReason:'', email:'', phone:'', dni:'', province:'', locality:'' };