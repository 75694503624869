import { Box, CssBaseline } from "@mui/material";
import { Main } from "./pages/Main";
import { BrowserRouter } from "react-router-dom";
import Footer from "./components/Footer/Footer";

function App() {
  return (
    <BrowserRouter>
      <Box
        sx={{
          backgroundColor: "#fafafa",
          minHeight: "65vh",
          paddingBottom: "5vh",
        }}
      >
        <CssBaseline />
        <Main />
      </Box>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
