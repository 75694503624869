import { makeStyles } from "@mui/styles";
import { ColorsCode } from "../../../utils/Colors/ColorsList";

export const useStyles = makeStyles({
  imagen: {
    maxHeight: "60px",
    minHeight: "50px",
    marginLeft: "10px",
    ["@media (max-width:900px)"]: {
      maxHeight: "60px",
      minHeight: "50px",
      marginLeft: "10px",
    },
  },
  containerInfoSocios: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    width: "100%",
    height: "33%",
    alignContent: "stretch",
    columns: 12,
    overflow: "auto",
    ["@media (max-width:1000px)"]: {
      flexDirection: "column",
      display: "inline",
      flexWrap: "nowrap",
      alignContent: "stretch",
    },
  },
  tituloSocios: {
    color: `${ColorsCode.orange} !important`,
    fontSize: "2rem !important",
    ["@media (max-width:800px)"]: {
      lineHeight: "1.8rem",
      textAlign: "center",
      fontSize: "1.3rem !important",
    },
    ["@media (max-width:600px)"]: {
      lineHeight: "1.8rem",
      textAlign: "center",
      fontSize: "1rem !important",
      paddingLeft: "9%",
    },
  },
  gridContainer: {
    marginRight: "8%",
    marginLeft: "8%",
    marginBottom: 15,
    width: "84%",
    border: `1px solid ${ColorsCode.light_grey} !important`,
  },
  gridInfoSocios: {
    borderRadius: "4px 4px 0 0 !important",
    backgroundColor: ColorsCode.dark_white,
  },
});
